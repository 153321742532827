<template>
	<div class="py-0">
    <div v-if="roleName === 'SYSTEM_ADMIN'">
      <v-row>
        <v-dialog v-model="dialogDelete" style="border: 100px white solid" max-width="380px"  transition="dialog-top-transition">
          <v-card class="pa-7">
            <v-card-title class="d-flex justify-center align-center">
              <v-row  dense>
                <v-col class="py-0 mb-1" cols="12">
                <span class="icon-background">
                  <v-icon dense color="red" style="font-size: 30px" class="my-0">mdi-alert-outline</v-icon>
                </span>
                </v-col>
                <v-col cols="12">
                  <span>{{ $t('deleteQuestion') }}</span>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text class="text-center">{{ $t('questionTextForDelete') }}</v-card-text>
            <v-card-actions class="mb-2">
              <v-row dense>
                <v-col cols="12">
                  <v-btn block depressed color="alert_red" class="text-color-white" @click="deleteHospitalConfirm">{{ $t('deleteField') }}</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn  outlined depressed color="main_black" block @click="closeDeleteDialog">{{ $t("cancel") }}</v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
		<v-row class="pt-3" v-if="roleName === 'SYSTEM_ADMIN' || roleName === 'SYSTEM_USER'">
			<v-col cols="12">
				<v-data-table
					v-if="filteredHospitals"
					class="pa-1 medical-staff-data-table"
					fixed-header
					dense
					:headers="headers"
					:headers-length="headers.length"
					:no-data-text="$t('no-data-available')"
					:loading-text="$t('loading')"
					:loading="loading"
					:search="appBarSearch"
					:items="filteredHospitals"
					item-key="id"
					sort-by="id"
					group-by="medicalInstitutionData.institutionName"
					:height="pageHeight(150)"
          ref="table"
          :footer-props="footerProps"
          :items-per-page="-1"
				>
					<template v-slot:item="{ item }">
						<tr @click="selectedHospitalRow(item)">
							<td class="text-left font-weight-medium">
								{{ item.hospitalName }}
							</td >
							<td class="text-right">
							<v-menu
								left
								bottom
								class="py-0"
								offset-y
								transition="slide-x-transition"
							>
								<template v-slot:activator="{on, attrs}">
									<v-btn
										icon
										color="main_black"
										v-bind="attrs"
										v-on="on"
									>
									<v-icon>mdi-dots-horizontal</v-icon>
									</v-btn>
								</template >
								<v-list class="px-0" dense >
									<v-list-item class="py-0 my-0" link dense @click="selectedHospitalRow(item)">
										<v-list-item-icon class="mx-1 ">
											<v-icon class="icon-img mdi-18px" color="black">mdi-eye</v-icon>
										</v-list-item-icon>
										<v-list-item-content class="font-bold font-size14">{{$t("view")}}</v-list-item-content>
									</v-list-item>
									<v-divider/>
									<v-list-item class="py-0 my-0" link dense @click="generateCSV(item)">
										<v-list-item-icon class="mx-1 ">
											<v-icon class="icon-img mdi-18px" color="main_black">mdi-file-excel</v-icon>
										</v-list-item-icon>
										<v-list-item-content class="font-weight-bold font-size14">CSV</v-list-item-content>
									</v-list-item>
									<v-list-item link dense @click="deleteHospitalById(item)">
									<v-list-item-icon class="mx-1 ">
										<v-icon v-if="roleName === 'SYSTEM_ADMIN'" color="main_red">mdi-trash-can-outline</v-icon>
									</v-list-item-icon>
									<v-list-item-content class="font-bold font-size14">{{$t("delete")}}</v-list-item-content>
									</v-list-item>
								</v-list>
							</v-menu>
							</td>
						</tr>
					</template>
					<template v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }">
						<td @click="toggle" class="py-0 my-0 text-left expand-data-table-office" :colspan="headers.length">
							<v-row class="align-center d-flex">
                <v-col class="py-0 pl-0" cols="4">
                  <v-btn small icon :ref="group" :data-open="isOpen">
                    <v-icon v-if="isOpen" small>mdi-arrow-down</v-icon>
                    <v-icon v-else small>mdi-arrow-right</v-icon>
                  </v-btn>
                  <span class="font-weight-bold ml-1 mr-1">{{ group }}</span>
                </v-col>
                <v-col cols="7" class="py-0 my-0">
                  <v-chip pill x-small color="primary" class="mx-2 text--black custom-chip-size" label>
                    {{ items.length }} {{ items.length > 1 ? $t('hospitals') : $t('hospital') }}
                  </v-chip>
                </v-col>
							</v-row>
						</td>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row v-if="roleName === 'ORGANIZATION_ADMIN'">
			<v-col cols="12">
				<v-data-table
					v-if="filteredHospitals"
					class="pa-1 medical-staff-data-table"
					fixed-header
					dense
					:headers="headers"
					:headers-length="headers.length"
					:no-data-text="$t('no-data-available')"
					:loading-text="$t('loading')"
					:loading="loading"
					:search="appBarSearch"
					:items="hospitalsWithAdmins"
					item-key="id"
					sort-by="id"
					@click:row="selectedHospitalRow"
					:height="pageHeight(150)"
					:single-expand="true"
					:expanded.sync="expanded"
          :footer-props="footerProps"
          :items-per-page="-1"
				>
					<template v-slot:item="{ item }">
						<tr @click="selectedHospitalRow(item)">
              <td class="text-left font-size12-5 font-weight-medium">
                <span>{{ item.hospitalName }}</span>
							</td>
              <td class="px-0 d-flex mt-2">
                  <v-chip class="mx-1" v-for="(admin, index) in item.admins" :key="index" label pill x-small color="primary">{{ admin }}</v-chip>
              </td>
              <td class="text-right">
								<v-menu
									left
									bottom
									offset-y
                  class="py-0"
									transition="slide-x-transition"
								>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn
                        icon
                        color="black"
                        v-bind="attrs"
                        v-on="on"
                    >
                      <v-icon class="mdi mdi-24px  "></v-icon>
                    </v-btn>
                  </template >
									<v-list>
										<v-list-item link dense @click="selectedHospitalRow(item)">
                    <v-list-item-icon class="mx-1 ">
                      <v-icon class="icon-img mdi-18px" color="black">mdi-eye</v-icon>
                    </v-list-item-icon>
											<v-list-item-content class="font-bold font-size14">{{ $t('view') }}</v-list-item-content>
										</v-list-item>
										<v-divider/>
									<v-list-item class="py-0 my-0" link dense @click="generateCSV(item)">
										<v-list-item-icon class="mx-1 ">
											<v-icon class="icon-img mdi-18px" color="main_black">mdi-file-document</v-icon>
										</v-list-item-icon>
										<v-list-item-content class="font-bold font-size14">CSV</v-list-item-content>
									</v-list-item>
									</v-list>
								</v-menu>
							</td>
						</tr>
					</template>
					<template v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }">
						<td class="text-left" :colspan="headers.length">
							<span>{{ group }}</span>
							<v-chip  class="mb-1 white--text" pill x-small color="primary">
							{{ items.length }}
							</v-chip>
						</td>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';

export default {
	data: () => ({
    footerProps: { 'items-per-page-options': [-1, 5, 10, 50, 100] },
    loading: false,
		headerClass: 'color-header',
		expanded: [],
		dialogDelete: false,
		hospitalIdToDelete: -1,
	}),
	computed: {
		...mapState({
			hospitals: (state) => state.hospitals.hospitals,
			filteredHospitals: (state) => state.hospitals.filteredHospitals,
      hospitalAdminUsers: (state) => state.users.hospitalAdmins,
      allVerifiers: (state) => state.verifiers.allverifires,
			generatedCsvFile: (state) => state.billings.generatedCsvFile,
		}),
		...mapGetters({
      pageHeight: 'pageHeight',
      roleName: 'authentication/getRole',
			appBarSearch: 'filterbar/getSearch',
			appBarMedicalInstitution: 'filterbar/getMedicalInstitution',
		}),
    hospitalsWithAdmins () {
      return this.hospitals.map(hospital => {
        return {
          ...hospital,
          admins: this.getHospitalAdmins(hospital),
        };
      });
    },
    headers () {
      var headers = [];
      if (this.roleName === 'SYSTEM_ADMIN' || this.roleName === 'SYSTEM_USER') {
        headers.push(
            {
              text: this.$t('name'),
              value: 'hospitalName',
              class: this.headerClass,
              width: 500,
            },
            {
              text: '',
              value: 'actions',
              class: this.headerClass,
              sortable: false,
            },
        );
      } else {
        headers.push(
        {
          text: this.$t('Office'),
          value: 'hospitalName',
            class: this.headerClass,
            width: 500,
        },
        {
          text: this.$t('admins'),
              value: 'admin',
            class: [this.headerClass, 'pl-6'],
            width: 50,
        },
        {
          text: '',
              value: 'actions',
            class: this.headerClass,
            sortable: false,
        },
        );
      }

      return headers;
    },
		filteredHospitals () {
			let calculateFileteredHospitals = this.hospitals;
			if (this.appBarMedicalInstitution !== 0 && this.appBarMedicalInstitution !== null) {
				// filter by medicalInstitutionId
				calculateFileteredHospitals = calculateFileteredHospitals.filter(h => h.medicalInstitutionId === this.appBarMedicalInstitution);
			}

			return calculateFileteredHospitals;
		},
	},
	async mounted () {
    await this.getHospitalAmdinsUsers();
		await this.getAllVerifiers();
		await this.getHospitals();
	},
	methods: {
      getHospitalAdmins (item) {
        const admins = [];
        this.hospitalAdminUsers.forEach(hospitalData => {
          if (hospitalData.hospitalAssignedData.id === item.id) {
            admins.push(`${hospitalData.firstName} ${hospitalData.lastName}`);
          }
        });
        return admins;
      },

    async getHospitalAmdinsUsers () {
      await this.$store.dispatch('users/getHospitalAdmins').then(() => {
        this.loading = false;
      });
    },
		async getAllVerifiers () {
			if (this.allVerifiers == null) {
				await this.$store.dispatch('verifiers/getAllVerifiers').then(() => {
				});
			}
		},
		async getHospitals () {
			this.loading = true;
			await this.$store.dispatch('hospitals/getHospitals').then(() => {
				this.loading = false;
			});
      this.toggleHeaders();
		},
    toggleHeaders () {
      const table = this.$refs.table;
      if (table) {
        const keys = Object.keys(table.$vnode.componentInstance.openCache);
        keys.forEach((x) => {
          table.$vnode.componentInstance.openCache[x] = false;
        });
      }
    },
		async editHospital (item) {
await this.$store.commit('hospitals/SELECTED_HOSPITAL', item);
this.$store.commit('SET_EDIT_DETAILS_DRAWER', true);
		},
		selectedHospitalRow (item) {
			this.editHospital(item);
		},
		async generateCSV (item) {
			const currentDate = new Date();
			const currentYear = currentDate.getFullYear();
			const currentMonth = currentDate.getMonth() + 1;
			const hospitalId = item.id;
			const data = { hospitalId: hospitalId, month: currentMonth, year: currentYear };
			await this.$store.dispatch('billings/generateCsvHospitalPatientsForMonth', data)
				.then(res => {
					showSuccessAlert(this.$t('success-operation'));
					const url = window.URL.createObjectURL(new Blob([this.generatedCsvFile.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'office-billings-csv.csv');
					document.body.appendChild(link);
					link.click();
				});
		},
		async deleteHospitalById (item) {
			this.dialogDelete = true;
			this.hospitalIdToDelete = item.id;
		},
		async deleteHospitalConfirm () {
			await this.$store.dispatch('hospitals/deleteHospital', this.hospitalIdToDelete).then(res => {
				if (res.data.resFlag) {
					showSuccessAlert(this.$t('success-operation'));
					this.getHospitals();
					this.closeDeleteDialog();
				} else {
					showErrorAlert(this.$t('failed-operation'));
				}
			});
		},
		closeDeleteDialog () {
			this.dialogDelete = false;
			this.hospitalIdToDelete = -1;
		},
	},
	watch: {
		async appBarMedicalInstitution (val) {
			// after filtering
		},
	},
};
</script>

<!--<style>-->
<!--/* *{-->
<!--	border: red solid 1px;-->
<!--} */-->

<!--.table-items-small{-->
<!--	font-size:small;-->
<!--}-->
<!--.table-items-medium{-->
<!--	font-size:medium;-->
<!--}-->
<!--.table-cols {-->
<!--	display: flex;-->
<!--	align-items: center;-->
<!--	min-width: 150px;-->
<!--}-->
<!--.image-container {-->
<!--	display: flex;-->
<!--	flex-direction: column;-->
<!--	justify-content: center;-->
<!--	align-items: flex-end;-->
<!--}-->
<!--.staff-name {-->
<!--	font-weight: bold;-->
<!--}-->

<!--.role-icon{-->
<!--	vertical-align: text-top;-->
<!--}-->
<!--.text-container {-->
<!--	display: flex;-->
<!--	flex-direction: column;-->
<!--	justify-content: center;-->
<!--	align-items: flex-start;-->
<!--	padding-left: 10px; /* or margin-left: 10px; */-->
<!--}-->
<!--.table-cols2{-->
<!--	display: flex;-->
<!--	align-items: center;-->
<!--}-->
<!--.table-cols3{-->
<!--	display: flex;-->
<!--	align-items: center;-->
<!--}-->
<!--.staff-office{-->
<!--	align-items: center;-->
<!--	min-width: 90px;-->
<!--}-->
<!--.staff-email{-->
<!--	color: #6600FF;-->
<!--	padding-left: 10px;-->
<!--}-->
<!--.staff-phone{-->
<!--	color: #6600FF;-->
<!--	padding-left: 0px;-->
<!--	min-width: 110px;-->
<!--}-->
<!--.table-cols2 img,-->
<!--.table-cols3 img {-->
<!--	padding:  5px;-->
<!--}-->
<!-- .color-header {-->
<!--	background-color: #ECEAF3 !important;-->
<!--	color: #090017;-->
<!-- }-->
<!--.v-data-footer{-->
<!--	justify-content: right;-->
<!--	margin-right: 0px;-->
<!--	padding-top: 10px;-->
<!--	padding-bottom: 10px;-->
<!--}-->

<!--.v-data-footer__icons-before{-->
<!--	border: #e1dce8 solid 1px;-->
<!--	border-radius: 5px;-->
<!--	margin-right: 10px;-->
<!--}-->

<!--.v-data-footer__icons-before:hover{-->
<!--	border: #bab0c9 solid 1px;-->
<!--}-->

<!--.v-data-footer__icons-after{-->
<!--	border: #e1dce8 solid 1px;-->
<!--	border-radius: 5px;-->
<!--	margin-right: 10px;-->
<!--}-->

<!--.v-data-footer__icons-after:hover{-->
<!--	border: #bab0c9 solid 1px;-->
<!--}-->

<!--.v-data-footer__icons-before .v-btn{-->
<!--	height: 30px;-->
<!--	width: 30px;-->
<!--	margin-right: 0px !important;-->
<!--}-->

<!--.v-data-footer__icons-before .v-btn .v-icon{-->
<!--	font-size: 16px;-->
<!--}-->

<!--.v-data-footer__icons-after .v-btn{-->
<!--	height: 30px;-->
<!--	width: 30px;-->
<!--	margin-left: 0px !important;-->
<!--}-->

<!--.v-data-footer__icons-after .v-btn .v-icon{-->
<!--	font-size: 16px;-->
<!--}-->

<!--.icon-background {-->
<!--  display: inline-flex;-->
<!--  align-items: center;-->
<!--  justify-content: center;-->
<!--  border-radius: 100%;-->
<!--  background-color: #FFE4E6; /* Change this to the desired color */-->
<!--  width: 50px; /* Adjust the width and height to create a circular shape */-->
<!--  height: 50px;-->
<!--}-->
<!--</style>-->
